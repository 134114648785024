import styled from 'styled-components'

const Container = styled.section`
  background-color: white;
  background: linear-gradient(180deg, #FFD972 0%, rgba(255, 217, 114, 0) 104.94%), #FFF;
  position: fixed;
  height: 100vh;
  width: 100vw;

  z-index: 20;
  left: 0;
  top: 0;
`

const AssetBlackDisc = styled.img`
  top: -160px;
  right: 0;
  height: 460px;
  position: absolute;

  @media (min-width: 768px) {
    height: 360px;
    top: 0;
  }

  @media (min-width: 900px) {
    height: 280px;
  }

  @media (min-width: 1280px) {
    height: 320px;
  }

  @media (min-width: 1920px) {
    height: 52vh;
  }
`

const AssetCassete = styled.img`
  position: absolute;
  width: 20vh;
  z-index: 25;

  bottom: 20px;
  right: 0;

  @media (min-width: 576px) {
    bottom: 40px;
    width: 280px;
  }

  @media (min-width: 768px) {
    width: 360px;
    bottom: 8vh;
    right: 2.5vw;
  }

  @media (min-width: 900px) {
    width: 400px;
    bottom: 2vh;
  }

  @media (min-width: 1280px) {
    width: 460px;
  }

  @media (min-width: 1920px) {
    width: 76vh;
  }
`

const AssetRadio = styled.img`
  position: absolute;
  height: 24vh;
  z-index: 25;

  bottom: 10vh;

  @media (min-width: 576px) {
    height: 320px;
  }

  @media (min-width: 768px) {
    bottom: 10vh;
    left: 0;
    height: 480px;
  }

  @media (min-width: 900px) {
    height: 400px;
  }

  @media (min-width: 1280px) {
    height: 72vh;
  }
`

const TextBox = styled.div`
  position: absolute;
  z-index: 30;

  bottom: 48vh;
  right: 28vw;

  @media (min-width: 768px) {
    right: 120px;
    bottom: 40vh;
  }

  @media (min-width: 900px) {
    right: 280px;
  }

  @media (min-width: 1024px) {
    right: 240px;
  }

  @media (min-width: 1280px) {
    right: 280px;
  }

  @media (min-width: 1920px) {
    right: 60vh;
    bottom: 40vh;
  }
`

export { Container, AssetBlackDisc, AssetCassete, AssetRadio, TextBox }