import { Text, useMediaQuery } from '@chakra-ui/react'
import { useSelector } from 'react-redux'
import Head from 'next/head'

import { getAuthUser } from 'redux/slices/auth/selector'
import Button from 'components/elements/Button'

import {
  AssetBlackDisc,
  AssetCassete,
  AssetRadio,
  Container,
  TextBox
} from './style'

const ErrorPage = () => {
  const user = useSelector(getAuthUser)
  const [isTablet] = useMediaQuery('(min-width: 768px)')

  return (
    <Container>
      <Head>
        <title>PMB 2022 | Not Found</title>
      </Head>
      <AssetBlackDisc
        src={
          !isTablet ? '/images/black-disc-sm.png' : '/images/black-disc-lg.png'
        }
        alt="blue-gate"
      />
      <AssetCassete
        src={!isTablet ? '/images/cassetes-sm.png' : '/images/cassetes-lg.png'}
        alt="cassetes"
      />
      <AssetRadio src="/images/radio-lg.png" alt="radio" />
      <TextBox>
        <Text
          className="
            text-black font-Literata font-bold
            text-3xl lg:text-5xl mb-4
          "
        >
          Page not found!
        </Text>
        <Text
          width={{ base: 'auto', lg: '100%' }}
          className="
            text-black font-SFProDisplay
            text-sm lg:text-base mb-7 lg:mb-10
          "
        >
          It seems that you are in the wrong page, <br /> you should find your
          way back!
          {user?.nama_panggilan ? `, ${user?.nama_panggilan}` : ''}!
        </Text>
        <Button type="primary" to="/home">
          Back to home
        </Button>
      </TextBox>
    </Container>
  )
}

export default ErrorPage
